// Ready the DOM
jQuery(document).ready(function() {
    // console.log("in google data js file");

    // ////////////// Search Functionality //////////////
    var getCurrentLocation, seachInput, searchResult, dataStringPincode, loadingTimeout,loading, myLat, myLng, detailError, options, onGeoSuccess, onGeoError, getPincode, getLocationResult, formWrapper;
    // getCurrentLocation    = document.getElementById('getCurrentLocation');
    // seachInput            = document.getElementById("searchInput");
    // seachButton           = document.getElementById("searchLocation");
    // searchResult          = document.getElementById("mapResult");
    // searchResultData      = document.getElementById("resultData");
    // formWrapper           = document.getElementById("nf-form-6-cont");

    // // Search button click perform actions
    // seachButton.addEventListener('click', (e)=>{
    //     if(seachInput.value < 99999){
    //         searchResult.innerHTML = '<span class="error">Please enter your pincode or click on use my current location to auto fetch you location<span>';
    //         e.preventDefault();
    //     }else{
            // getLocationResult();

    //         e.preventDefault();
    //     }
    // })


    // // Get current location
    // options = {
    //     enableHighAccuracy: true,
    //     timeout: 5000,
    //     maximumAge: 0
    // };

    // getCurrentLocation.addEventListener("click", getLocation);

    // // Getting GEO coding
    // function getLocation() {
    //     let geolocation = navigator.geolocation;
    //     if (geolocation) {
    //         searchResult.innerHTML = '<svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 20" enable-background="new 0 0 0 0" xml:space="preserve"> <circle fill="#f4bc40" stroke="none" cx="6" cy="10" r="6"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"/></circle><circle fill="#f4bc40" stroke="none" cx="26" cy="10" r="6"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"/></circle> <circle fill="#f4bc40" stroke="none" cx="46" cy="10" r="6"> <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"/></circle> </svg>';
    //         geolocation.getCurrentPosition(onGeoSuccess, onGeoError, options);
    //     } else {
    //         console.log("Geolocation is not supported by this browser.");
    //     }
    // }

    // onGeoSuccess = (position)=> {
    //     myLat = position.coords.latitude;
    //     myLng = position.coords.longitude;
    //     if(myLat == '' || myLat == ''){}

    //     else{
    //         getPincode();
    //     }
    // }

    // // GEO coding error management
    // onGeoError = (error) => {
    //     if(error.code === error.PERMISSION_DENIED) {
    //     detailError = "Permission denied!";
    //     }
    //     else if(error.code === error.POSITION_UNAVAILABLE) {
    //     detailError = "Location information is unavailable!";
    //     }
    //     else if(error.code === error.TIMEOUT) {
    //     detailError = "Request timeout!"
    //     }
    //     else if(error.code === error.UNKNOWN_ERROR) {
    //     detailError = "An unknown error occurred!"
    //     }
    //     searchResult.innerHTML = detailError;
    // }

    // // Getting pincode getMyCurrentLocation
    // getPincode = ()=> {
    //     jQuery.getJSON( `https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json?prox=${myLat},${myLng}&mode=retrieveAddresses&maxresults=1&gen=9&apiKey=2G9XhwUEYrJRIsQjD_5QKHnqm4V3JAEzcmDJjI67g5Q`, function(data) {
    //         seachInput.value = data.Response.View[0].Result[0].Location.Address.PostalCode;
    //         searchResult.innerHTML = 'Finding MTP Providers....';
    //         getLocationResult();
    //     })
    //     .done( function() {
    //         console.log('done');
    //     }).fail( function() {
    //         console.log('fail');
    //     }).always( function() {
    //     console.log('always');
    //     });
    // }

    // Getting nearby result data from MYsql
    // getLocationResult = ()=> {
        // searchResult.innerHTML = '<svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 20" enable-background="new 0 0 0 0" xml:space="preserve"> <circle fill="#f4bc40" stroke="none" cx="6" cy="10" r="6"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"/></circle><circle fill="#f4bc40" stroke="none" cx="26" cy="10" r="6"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"/></circle> <circle fill="#f4bc40" stroke="none" cx="46" cy="10" r="6"> <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"/></circle> </svg>';
        // jQuery.getJSON( `https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey=2G9XhwUEYrJRIsQjD_5QKHnqm4V3JAEzcmDJjI67g5Q&searchtext=${seachInput.value}`, function(data) {
        //     lat                     = data.Response.View[0].Result[0].Location.DisplayPosition.Latitude;
        //     long                    = data.Response.View[0].Result[0].Location.DisplayPosition.Longitude;
        //     pincode                 = seachInput.value;
        //     dataStringLocation      = 'lat='+ lat + '&long=' + long + '&pincode=' + pincode;
        //     lat_long                = lat + ',' + long;
        //     jQuery('#nf-field-77').val(lat_long).trigger('change');
            // jQuery.ajax({
            //     type: "POST",
            //     url: "https://"+window.location.hostname+"/wp-content/themes/pratigya/sendOtpModule/googledata.php",
            //     // data: dataStringLocation,
            //     success: function(data) {
            //       response = jQuery.parseJSON(data);
            //       console.log(data);
            //     }
            //   });

    //     })
    //     .done( function() {
    //         console.log('done');
    //     }).fail( function() {
    //         console.log('fail');
    //     }).always( function() {
    //     console.log('always');
    //     });
    // }
});
